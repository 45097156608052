import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/kiss_the_cook/image1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/kiss_the_cook/image2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/kiss_the_cook/image3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/kiss_the_cook/image4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="KissTheCook | Wunderkammer Lüdenscheid"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/sZMprl2LPUc"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>Kiss the cook! - Rezepte aus der Wunderküche</h3>
            <p>
              Ist Kochen eine Kunst? Ist Kochen eine Philosophie? Oder ist
              Kochen einfach nur Kochen? In jedem Fall folgt dem Kochen das
              Essen. Und das ist existenziell. Wenn man kocht, denkt man
              automatisch über die Dinge nach, mit denen man da umgeht. Man
              sinniert über Gemüse, Getreide, Fisch und Fleisch. Es sind
              merkwürdige Gedanken, lustige, traurige. Man meditiert über
              Grünkohl, Gänsebraten und Guacomole.
            </p>
            <div className="my-32">
              <GatsbyImage
                className="max-w-672"
                objectFit="contain"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Kiss the cook!"
              />
            </div>
            <p>
              Wir geben Kindern den Kochlöffel in die Hand – mit der
              Aufforderung: Kocht euer Lieblingsgericht, oder das traditionelle
              Familiengericht oder ein Gericht, dass nach Urlaub schmeckt, oder…
              Und macht aus eurem Tun eure eigene kleine Performance/­Kochshow.
              Ihr könnt dokumentieren, kommentieren, filmen, zeichnen und eigene
              Sounds kreieren…
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-40 lg:-mb-92 self-end z-10 w-full md:w-1/2"
                objectFit="cover"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Kiss the cook!"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Kiss the cook!"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Kiss the cook!"
              />
            </div>
            <p>
              Die Ergebnisse sind nun Teil der Wunderkammer und – verlängert –
              bis zum 31. Januar 2022 auf der Videowall im Sterncenter
              Lüdenscheid zu sehen.
            </p>
            <div className="pt-32">
              <p>
                <b>Mitwirkende Autoren*innen</b>
                <br />
                Lasse und Jimmy aus Düsseldorf, Finn aus Lüdenscheid, Pola aus
                Berlin, Loan aus Berlin, Neo aus Berlin, Lucie aus Düsseldorf,
                Merlin aus Bremen, Luis aus Lüdenscheid, Carlo, Demian und Oda
                aus Düsseldorf, Felix aus Lüdenscheid
              </p>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Kiss the cook!</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Ist Kochen eine Kunst? Ist Kochen eine Philosophie? Oder ist
      Kochen einfach nur Kochen? In jedem Fall folgt dem Kochen das
      Essen. Und das ist existenziell. Wenn man kocht, denkt man
      automatisch über die Dinge nach, mit denen man da umgeht. Man
      sinniert über Gemüse, Getreide, Fisch und Fleisch. Es sind
      merkwürdige Gedanken, lustige, traurige. Man meditiert über
      Grünkohl, Gänsebraten und Guacomole."
      publicURL={props.data.image1.publicURL}
      title="Kiss the cook!"
    />
  );
};

export default Page;
